import { gsap } from "gsap";

export default class GridScroll {
	constructor() {
		this.gridItems = [...document.querySelectorAll('.grid-scroll .grid-scroll-item')];

		this.init();
	}

	init() {
		this.gridItems.forEach(item => {
			const image = item.querySelector('.grid-scroll-item-img');
			const yPercentRandomVal = gsap.utils.random(-10,10);

			gsap.timeline()
			.addLabel('start', 0)
			.set(image, {
				transformOrigin: `200% 50%`
			}, 'start')
			.to(image, {
				ease: 'none',
				startAt: {rotationZ: -30},
				rotationZ: 30,
				scrollTrigger: {
					trigger: item,
					start: "top bottom",
					end: "bottom top",
					scrub: true,
				}
			}, 'start')
			.to(item, {
				ease: 'none',
				yPercent: yPercentRandomVal,
				scrollTrigger: {
					trigger: item,
					start: "top bottom",
					end: "top top",
					scrub: true
				}
			}, 'start');

		});
	}
}
