import { gsap } from "gsap";

export default class TextMarq {
	constructor() {
		this.textWraps = document.querySelectorAll('.marquee .title');

		this.init();
	}

	init() {
		this.textWraps.forEach((textWrap) => {
			const wrapLength = textWrap.clientWidth;
			const inner = textWrap.innerHTML;
			textWrap.innerHTML = `${inner} <span class="clone">${inner}</span>`;

			const marqTl = gsap.timeline({ repeat: -1, paused: false });

			marqTl.fromTo(textWrap, 10, { left: 5 }, { left: -wrapLength, ease: 'none'  }, 0);
		});
	}
}
