import barba from '@barba/core';
import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

import Static from './static';
import HomeAnimations from './homeAnimations';
import TextMarq from './textMarq';
import ContentBlockAnimations from './contentBlockAnimations';
import GridScroll from './gridScroll';
import MusicSuperAnimations from './musicSuperAnimations';
import AboutAnimations from './aboutAnimations';
import AudioInteractions from './audio';
import ProgressIndicator from './progressIndicator';
import Nav from './nav';

const faviconTag = document.querySelector('.faviconTag');
const isDark = window.matchMedia('(prefers-color-scheme: dark)');

const changeFavicon = () => {
	if (isDark.matches) faviconTag.href = 'https://res.cloudinary.com/houseofgiants/image/upload/v1663876963/Back%20Forty/favicon-32x32_light_rm22rx.png'
	else faviconTag.href = 'https://res.cloudinary.com/houseofgiants/image/upload/v1663876963/Back%20Forty/favicon-32x32_dark_keon2n.png'
};

changeFavicon();

new Static({
	domElement: document.getElementById('static')
});

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

// const hidePlay = () => {
// 	const audio = document.querySelector('.sound');
// 	audio.classList.add('-hide');
// };

const showPlay = () => {
	const audio = document.querySelector('.sound');
	audio.classList.remove('-hide');
};

barba.init({
	views: [
		{
			namespace: 'home',
			once() {
				new Nav();
				new ProgressIndicator();
				new AudioInteractions();
				new HomeAnimations();
				new TextMarq();
				showPlay();
			},
			async beforeEnter() {
				new Nav();
				new ProgressIndicator();
				new AudioInteractions();
				new HomeAnimations();
				new TextMarq();
				showPlay();
			},
			async afterEnter() {
				window.scrollTo({top: 0});
				showPlay();
			},
			async beforeLeave() {
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		},
		{
			namespace: 'artist-management',
			once() {
				new Nav();
				new ProgressIndicator();
				new GridScroll();
				new AudioInteractions();
				new ContentBlockAnimations();
			},
			async beforeEnter() {
				new Nav();
				new ProgressIndicator();
				new GridScroll();
				new AudioInteractions();
				new ContentBlockAnimations();
			},
			async beforeLeave() {
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		},
		{
			namespace: 'music-supervision',
			once() {
				new Nav();
				new ProgressIndicator();
				new AudioInteractions();
				new TextMarq();
				new ContentBlockAnimations();
				new MusicSuperAnimations();
			},
			async beforeEnter() {
				new Nav();
				new ProgressIndicator();
				new AudioInteractions();
				new TextMarq();
				new ContentBlockAnimations();
				new MusicSuperAnimations();
			},
			async beforeLeave() {
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		},
		{
			namespace: 'about',
			once() {
				new Nav();
				new ProgressIndicator();
				new AudioInteractions();
				new AboutAnimations();
				new ContentBlockAnimations();
			},
			async beforeEnter() {
				new Nav();
				new ProgressIndicator();
				new AudioInteractions();
				new AboutAnimations();
				new ContentBlockAnimations();
			},
			async beforeLeave() {
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		},
	],
  transitions: [{
    name: 'default-transition',
		sync: true,
		async leave({ current }) {
			await gsap
				.to(
					current.container,
					{
						autoAlpha: 0,
						duration: 2,
						scale: 1.5,
						rotate: '-7deg',
						filter: 'blur(10px)',
						y: -400,
						ease: 'power4.inOut',
						zIndex: 1,
					}
				);
		},
		async enter({ next }) {
			await gsap
				.fromTo(
					next.container,
					{
						clipPath: 'polygon(0% 100%, 100% 110%, 100% 100%, 0% 100%)',
						zIndex: 2,
					},
					{
						clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
						duration: 2,
						ease: 'power4.inOut',
						zIndex: 2,
						onComplete: () => {
							next.container.style.clipPath = 'none';
						}
					}
				)
		},
	}]
});

ScrollTrigger.config({ ignoreMobileResize: true})
ScrollTrigger.normalizeScroll(true);
