import { gsap } from "gsap";

export default class ProgressIndicator {
  constructor() {
    this.init();
  }

  init() {
    gsap.fromTo('.progress-wrap path', {
      drawSVG: 0
    }, {
      drawSVG: '100%',
      scrollTrigger: {
        start: 0,
        end: 'max',
        scrub: 1,
      }
    });

    gsap.fromTo('.progress-wrap', {
      xPercent: 100,
      autoAlpha: 0
    }, {
      xPercent: 0,
      autoAlpha: 1,
      scrollTrigger: {
        start: 5,
        toggleActions: 'play none none reverse'
      }
    });
  }
}