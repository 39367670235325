import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class Nav {
  constructor() {
    this.nav = document.querySelector('.nav');
    this.init();
  }

  init() {
		const showAnim = gsap.from(this.nav, {
			yPercent: -100,
			paused: false,
			duration: 0.5,
		}).progress(1);

		ScrollTrigger.create({
			start: 'top top-=1',
			end: 99999,
			onUpdate: (self) => {
				self.direction === -1 ? showAnim.play() : showAnim.reverse()
			}
		});
	}
}