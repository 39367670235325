export default class AudioInteractions {
  constructor() {
		this.audio = document.querySelector('.bg-audio');
    this.soundToggle = document.querySelector('.sound');

    this.init();
  }

  init() {
    this.soundToggle?.addEventListener('click', (e) => {
      const sub = e.target.querySelector('sub');
      if(this.audio.paused) {
        this.soundToggle.classList.add('-active');
        sub.textContent = 'groovin\'';
        this.play();
        e.stopImmediatePropagation();
        e.preventDefault();
      } else {
        this.soundToggle.classList.remove('-active');
        sub.textContent = 'sound';
        this.mute();
        e.stopImmediatePropagation();
        e.preventDefault();
      }

      e.stopPropagation();
      e.preventDefault();
    });
  }

  mute() {
    this.audio.muted = true;
    this.audio.pause();
  }

  play() {
    this.audio.muted = false;
    this.audio.play();
  }
  
  showPlay() {
    this.soundToggle.classList.remove('-hide');
  }
}