import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class MusicSuperAnimations {
	constructor() {
		this.workGrid = document.querySelectorAll('.work-grid-wrap');
		this.workItems = document.querySelectorAll('.work-item');

		this.init();
	}

	init() {
		this.workItems.forEach((workItem) => {
			this.getYtThumb(workItem);
			this.playYtVid(workItem);
		});

		this.workGrid.forEach((grid, i) => {
			const workGridTl = gsap.timeline();
			const dir = i % 2 ? -20 : 20;

			workGridTl
				.from(grid, { xPercent: dir, duration: 0.5 });

			ScrollTrigger.create ({
				animation: workGridTl,
				trigger: grid,
				start: 'top bottom',
				end: 'center center-=100',
				scrub: 2,
				ease: 'circ.inOut'
			});
		})
	}

	playYtVid(el) {
		if (el) {
			el.addEventListener('click', () => {
				const audio = document.querySelector('.bg-audio');
				const soundToggle = document.querySelector('.sound');
				const sub = document.querySelector('.sound sub');

        sub.textContent = 'sound';
				soundToggle.classList.remove('-active');
        audio.pause();

				const vid = el.querySelector('iframe');
				el.classList.toggle('-active');
				const isActive = el.classList.contains('-active') ? 1 : 0;
				vid.src = `${vid.src.split('?')[0]}?autoplay=${isActive}&modestbranding=1&${vid.src.split('?')[1]}`;
			}, { passive: true });

		};
	}

	getYtThumb(el) {
		const iframe = el.querySelector('iframe');
		const src = iframe.getAttribute('src');
		let id;

		if (src.includes('youtube.com')) {
			id = src.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
		} else if (src.includes('player.vimeo.com')) {
			id = src.match(/vimeo.*\/(\d+)/i).pop();
		}

		if (id.length == 11) {
			const thumb = document.createElement('img');
			thumb.src = `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
			el.querySelector('.video').prepend(thumb);
		} else {
			const thumb = document.createElement('img');
			fetch(`https://vimeo.com/api/v2/video/${id}.json`)
				.then((res) => res.json())
				.then((data) => {
					thumb.src = data[0].thumbnail_large;
					el.querySelector('.video').prepend(thumb);
				});
		}
	}
}
