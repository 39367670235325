import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);
export default class HomeAnimations {
	constructor() {
		this.init();
	}

	init() {
		const preloadTl = gsap.timeline();
		const navTl = gsap.timeline();
		const heroTl = gsap.timeline();
		const videoIntroTl = gsap.timeline();
		const heroTextTl = gsap.timeline();
		const whoTl = gsap.timeline();
		const musicSuperTl = gsap.timeline();
		const artistMgmtTl = gsap.timeline();
		// const homeAudioTl = gsap.timeline();

		const aboutSplitLines = new SplitText('.who-dis .line-split', {type: 'lines', linesClass: 'split-line'});
		const introSplitLines = new SplitText('.hero .display div', {type: 'lines', linesClass: 'split-line'});

		preloadTl.from(
			'.preloader .logo',
			{
				opacity: 0,
				delay: 0.25
			}
		);

		preloadTl.fromTo(
			'.preloader .logo',
			{
				background: 'linear-gradient(0deg, var(--color-primary-light) 0%, var(--color-primary-dark) 0%)'
			},
			{
				duration: 1,
				background: 'linear-gradient(0deg, var(--color-primary-light) 100%, var(--color-primary-dark) 100%)',
				delay: 0.5,
				ease: 'power4.inOut',
			}
		);

		preloadTl.to(
			'.preloader',
			{
				duration: 1,
				opacity: 0,
				delay: 0.25,
				ease: 'power4.inOut',
			}
		)

		navTl.from('.nav', { opacity: 0, duration: 3, ease: 'power4.inOut', delay: 2.5 })

		// homeAudioTl.fromTo('.bg-audio', { volume: 0 }, { volume: 1 });

		heroTextTl
			.from(introSplitLines.lines, { yPercent: 100, rotate: '7deg', opacity: 0, delay: 2.5, duration: 1.5, ease: 'power4.inOut',  stagger: 0.05 });

		videoIntroTl
			.from('.video video', { opacity: 0, duration: 2.5, ease: 'power4.inOut' });

		heroTl
			.to('.video', { width: '100%', height: '100%', x: 0, y: 0, top: 0, left: 0, duration: 0.5 });

		whoTl
			.to('.who-dis .text-marq', { x: 0 })
			.to('.who-dis .text-marq', { opacity: 0, delay: 0.5 })
			.to('.who-dis .overlay-wrap', { opacity: 1 })
			.from(aboutSplitLines.lines, { opacity: 0, y: 100, stagger: 0.2 });

		artistMgmtTl
			.to('.artist-management .video', { opacity: 1 })
			.to('.artist-management .text-marq', { x: 0 })
			.to('.artist-management .text-marq', { opacity: 0, delay: 0.5 });

		musicSuperTl
			.to('.music-supervision .video', { opacity: 1 })
			.to('.music-supervision .text-marq', { x: 0 })
			.to('.music-supervision .text-marq', { opacity: 0, delay: 0.5 });

		// ScrollTrigger.create({
		// 	animation: homeAudioTl,
		// 	trigger: '.who-dis',
		// 	start: 'top bottom',
		// 	scrub: 1,
		// })

		setTimeout(() => {
			ScrollTrigger.create ({
				animation: heroTl,
				trigger: '.hero .video',
				start: 'top center',
				endTrigger: '.who-dis .text-marq-wrap',
				end: 'bottom center',
				scrub: 1.5,
			});
		}, 300);

		ScrollTrigger.create({
			animation: whoTl,
			trigger: '.who-dis',
			start: 'top top',
			end: '+=3000',
			scrub: 1,
			pin: true,
		});

		ScrollTrigger.create({
			animation: artistMgmtTl,
			trigger: '.artist-management',
			start: 'top top',
			end: '+=1666',
			scrub: 1,
			pin: true,
		});

		ScrollTrigger.create({
			animation: musicSuperTl,
			trigger: '.music-supervision',
			start: 'top top',
			end: '+=1666',
			scrub: 1,
			pin: true,
		});
	}
}
