import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class AboutAnimations {
	constructor() {
		this.init();
	}

	init() {
		gsap.utils.toArray('.about').forEach((about) => {
			const titleTl = gsap.timeline();
			const textTl = gsap.timeline();
			const imgTl = gsap.timeline();

			const title = about.querySelectorAll('.title');
			const text = about.querySelectorAll('.bio p');
			const img = about.querySelectorAll('.bio img');

			titleTl
				.from(title, { opacity: 0, duration: 0.666, ease: 'Power2.easeInOut' });
			textTl
				.from(text, { x: 100, opacity: 0, ease: 'Power2.easeInOut' });
			imgTl
				.from(img, { x: -100, opacity: 0, ease: 'Power2.easeInOut' });

			ScrollTrigger.create({
				animation: titleTl,
				trigger: about,
				start: 'top center',
			});

			ScrollTrigger.create({
				animation: imgTl,
				trigger: about,
				start: 'top top+=350',
			});

			ScrollTrigger.create({
				animation: textTl,
				trigger: about,
				start: 'top top+=400',
			});
		});
	}
}
