import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class ContentBlockAnimations {
	constructor() {
		this.init();
	}

	init() {
		gsap.utils.toArray('.content-block:not(.about)').forEach((block) => {
			const titleTl = gsap.timeline();
			const textTl = gsap.timeline();

			const callout = block.querySelectorAll('.callout');
			const text = block.querySelectorAll('.text');

			titleTl
				.from(callout, { x: 100, opacity: 0, duration: 0.666, ease: 'Power2.easeInOut' });
			textTl
				.from(text, { x: 100, opacity: 0, ease: 'Power2.easeInOut' });

			ScrollTrigger.create({
				animation: titleTl,
				trigger: block,
				start: 'top top+=550',
			});

			ScrollTrigger.create({
				animation: textTl,
				trigger: block,
				start: 'top bottom-=250',
			});
		});
	}
}
