import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import fragment from '../shaders/fragment.glsl';
import vertex from '../shaders/vertex.glsl';

export default class Static {
	constructor(options) {
		this.container = options.domElement;
		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight; 
		this.scene = new THREE.Scene();
		this.camera = new THREE.PerspectiveCamera( 50, this.width / this.height, 0.01, 10 );
		this.renderer = new THREE.WebGLRenderer( {
			antialias: true,
		} );

		this.container.appendChild( this.renderer.domElement );
		this.camera.position.z = 0.5;
		this.renderer.setSize( this.width, this.height );
		this.renderer.setPixelRatio(window.devicePixelRatio);

		this.time = 0;
		this.resize();
		this.postProcess();
		this.render();
		this.setupResize();
	}

	resize() {
		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;
		this.renderer.setSize( this.width, this.height );
		this.camera.aspect = this.width/this.height; 
		this.camera.updateProjectionMatrix(); 
	}

	setupResize() {
		window.addEventListener( 'resize', this.resize.bind(this), { passive: true } );
	}

	postProcess() {
		this.composer = new EffectComposer(this.renderer);
		this.renderPass = new RenderPass(this.scene, this.camera);

		this.composer.addPass(this.renderPass);

		this.customPass = new ShaderPass({
			uniforms: {
				tDiffuse: { type: "t", value: null },
				amount:   { type: "f", value: this.time },
			},
			vertexShader: vertex,
			fragmentShader: fragment
		})

		this.customPass.renderToScreen = true;
		this.composer.addPass(this.customPass);
	}

	render() {
		const timer = Date.now() * 0.0002;
		this.camera.position.x = Math.cos(timer) * 5;
		this.camera.position.z = Math.sin(timer) * 5;
		this.camera.lookAt(new THREE.Vector3(0,1,0));

		this.time += 0.01;
		this.customPass.uniforms.amount.value = this.time;

		this.composer.render();
		requestAnimationFrame(this.render.bind(this));
	}
}